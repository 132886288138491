/* Stili di base per il componente Home */
.home-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding: 80px;
  flex-direction: column;
}

.robot-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-120%, -38%);
  max-width: 350px;
}

.content-container {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
}

.logo {
  margin-bottom: 0px;
  max-width: 300px;
}

.new-pasticceria-text {
  color: white;
  font-weight: bold;
  font-size: 30px;
  margin: 120px 0 20px 0;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 25px #fff, 0 0 30px #fff;
}

.click-button {
  background-color: #80c7b8;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  font-size: 25px;
  text-shadow: 2px 2px 2px black;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

